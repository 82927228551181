<template>
  <section
    v-show="products.length"
    class="discount-catalog"
  >
    <div class="container">
      <div class="page__top discount-catalog__top">
        <h2 class="title">{{ $t("bestOffer") }}</h2>
        <div class="page__top-buttons discount-catalog__buttons">
          <locale-router-link
            class="page__top-link discount-catalog__link"
            to="catalog"
          >
            {{ $t("readMore") }}
          </locale-router-link>
          <div
            class="page__top-arrows discount-catalog__arrows"
            v-if="!$isMobile()"
          >
            <button
              class="discount-catalog__prev"
              @click="prevSlide"
            >
              <img
                src="@/assets/img/icons/prev-arrow-white.svg"
                alt=""
              />
            </button>
            <button
              class="discount-catalog__next"
              @click="nextSlide"
            >
              <img
                src="@/assets/img/icons/next-arrow-white.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <div
        class="discount-catalog__slider"
        v-if="!$isMobile()"
      >
        <swiper
          ref="swiperRef"
          :options="swiperOption"
        >
          <swiper-slide
            v-for="item in products"
            :key="item.id"
          >
            <product-card :item="item" />
          </swiper-slide>
        </swiper>
      </div>
      <div
        class="discount-catalog__mobile"
        v-else
      >
        <div
          v-for="(item, index) in products"
          :key="item.id"
          :class="{ 'discount-catalog__mobile-list': index < 5 }"
        >
          <product-card
            v-if="index < 5"
            :item="item"
          />
        </div>
      </div>
      <!-- <products-list v-if="products" :items="products" /> -->
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"

import { getAllProducts } from "@/api/products"

export default {
  name: "DiscountList",
  components: {
    // ProductsList: () => import("@/components/products/ProductsList.vue"),
    ProductCard: () => import("@/components/products/ProductCard.vue"),
  },

  data() {
    return {
      products: [],
      swiperOption: {
        lazy: true,
        slideTo: 1,
        slidesPerView: 1,
        loop: true,
        // spaceBetween: ,
        initialSlide: 0,
        centeredSlides: false,
        responsive: true,
        breakpoints: {
          600: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: 3,
          },
          1000: {
            slidesPerView: 4,
          },
          // 1200:{
          //   slidesPerView:
          // }
        },
      },
    }
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    swiper() {
      return this.$refs?.swiperRef
    },
  },
  watch: {
    selectedCity: {
      deep: true,
      handler() {
        getAllProducts(1, 500, this.selectedCity.id).then((response) => {
          this.products = response.data
        })
      },
    },
  },
  mounted() {
    if (this.selectedCity) {
      getAllProducts(1, 500, this.selectedCity?.id).then((response) => {
        this.products = response.data
      })
    }
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev()
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext()
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
.title {
  font-size: 38px;
  line-height: 110%;
  font-family: "CeraPro Bold";
  color: #2d2f32;
}
.swiper-container {
  // overflow: visible;
  // display: none;
  // clip-path: inset(-100vw -100vw -100vw 0);
}
.discount-catalog__mobile-list {
  margin-right: 10px;
}
@media (max-width: 768px) {
  .title {
    font-size: 20px;
    /* max-width: 140px; */
  }
  .product-block__descr {
    font-size: 14px;
  }
  .discount-catalog__mobile-list {
    border-bottom: 1px solid #f3f3f3;
  }
}
</style>
